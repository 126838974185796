<template>
  <div>
    <div class="header">
      <div class="title">兴趣性格能力测评</div>
      <div class="div" @click="goevaluation">开始测评</div>
    </div>
    <div v-html="data.content" class="content"></div>
  </div>
</template>

<script>
import { getContentInfo } from "@/api/comprehensive";
import { getUserQueryCount } from "@/api/dataquery";
export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.getContentInfo();
  },
  methods: {
    getContentInfo() {
      getContentInfo({ contentType: 2, type: 3 }).then((res) => {
        if (res.code == 1) {
          this.data = res.data || {};
        }
      });
    },
    goevaluation() {
      let sessionId = sessionStorage.getItem("sessionId") || null;
      if (sessionId) {
        // 查询次数
        getUserQueryCount({ type: 1 }).then((res) => {
          if (res.code == 1) {
            let data = res.data || {};
            let validityTime = data.validityTime;
            if (!validityTime) {
              this.$message.error("有效期已过期，请前往OK志愿小程序购买");
            } else {
              let validDate = new Date(validityTime.replace(/-/, "/"));
              if (new Date().getTime() > validDate.getTime()) {
                this.$message.error("有效期已过期，请前往OK志愿小程序购买");
              } else {
                if (data.usageCount > 0) {
                  this.$router.push("/volunterservce/Comprehensive/evaluation");
                } else {
                  this.$message.error("使用次数已用完，请前往OK志愿小程序购买");
                }
              }
            }
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "请先登录",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    height: 60px;
    width: 770px;
    box-sizing: border-box;
    padding-left: 105px;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    color: #333333;
  }
  .div {
    width: 150px;
    height: 32px;
    margin-top: 14px;
    background: #1ab394;
    border-radius: 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }
}
/deep/.content {
  width: 750px;
  margin: 0 auto;
  p,
    span,
    div,
    font,
    section,
    table  {
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto 0;
    margin-bottom: 0.4rem;
  }
}
</style>
